
<template>
    <div class="home">
        <div class="head content Between">
            <div class="head_logo pointer" @click="toHome">
                <img src="@/assets/img/home/logo1.png" alt="" />
            </div>
            <div class="fr flex-center">
                <div class="icon_phone">
                    <img src="@/assets/img/home/phone.png" alt="" />
                </div>
                <div>
                    <p class="service_hotline">全国服务热线</p>
                    <p class="phone">{{ $store.state.Information.fax }}</p>
                </div>
                <div class="links flex-center pointer">
                    <div class="links_div Between">
                        <div class="name">果尔佳站群</div>
                        <img src="@/assets/img/icon_x.png" alt="" />
                    </div>
                    <div class="child_div">
                        <a class="list_a flex-center" :href="item.stationGroupLink" target="_blank"
                            v-for="(item, index) in GejGroup" :key="index">
                            {{ item.stationGroupName }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="navBar" :class="isFiexd == true ? 'fixedHead' : ''" @mouseleave="classifyIndex = null">
            <div class="content Around">
                <div class="list display pointer" :class="navBarIndex == item.id ? 'navActive' : ''"
                    v-for="(item, index) in navBarList" :key="index" @click="changeBtn(item, index)"
                    @mouseenter="mouseEnter(index)">
                    <!-- <img
						class="icon_class"
						v-if="item.id == 1"
						src="@/assets/img/home/class.png"
                                                                                                                                                            						alt=""/> -->
                    {{ item.name }}
                    <div class="line"></div>
                </div>
            </div>
            <div class="classify_box" @mouseleave="classifyIndex = null" v-if="classifyIndex == 0 || classifyIndex == 1">
                <div class="content classifyCont flex">
                    <div class="fl">
                        <ul class="flex flex-wrap">
                            <li class="ul_list flex-center column pointer" v-for="(item, index) in classificationData"
                                :key="index" @click="pullDown(item.menuName)" @mouseenter="mouseEnterList(item.menuName)">
                                <img :src="item.iconImgUrl" alt="" />
                                <div class="name flex-center">
                                    {{ item.menuName }}
                                    <span class="triangle" v-if="item.menuName == '技能培训' || item.menuName == '安全培训'
                                        "></span>
                                    <ul class="more_ul" v-if="(item.menuName == '技能培训' && isShow == true) ||
                                        (item.menuName == '安全培训' && isShow == true)
                                        ">
                                        <li class="more_li flex-center" v-for="(items, indexs) in item.personCompanyMenuVOS"
                                            :key="indexs" @click.stop="sublevel(items.menuName)">
                                            {{ items.menuName }}
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="fr">
                        <img :src="classifyIndex == 0
                                ? $store.state.Information.personServerBannerImgUrl
                                : $store.state.Information.companyServerBannerImgUrl
                            " alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div class="content_box">
            <router-view></router-view>
        </div>
        <PlLazy :time="$store.state.timeData">
            <Footer></Footer>
        </PlLazy>
        <div class="sidebar flex-center column">
            <div class="wx_div display column">
                <img :src="$store.state.Information.appletQrCodeUrl" alt="" />
                <div class="wechatPublicAccount">学校小程序</div>
            </div>
            <div class="list pointer flex-center column" v-for="(item, index) in sidebarList" :key="index"
                @click="toCourse(item)">
                <img :src="item.imgUrl" alt="" />
                <div class="name">{{ item.name }}</div>
                <div class="names">{{ item.names }}</div>
            </div>
            <div class="list pointer flex-center column" @click="service">
                <img src="@/assets/img/sidebar/3.png" alt="" />
                <div class="name">在线咨询</div>
            </div>
            <div class="list pointer top_div display" @click="toTop" v-if="goTopShow == true">
                <img src="@/assets/img/sidebar/4.png" alt="" />
            </div>
        </div>

        <div class="popover_Box" v-if="popover != ''">
            <div class="maskLayer"></div>
            <div class="popove_main ">
                <div class="title">果尔佳职业技能培训学校</div>
                <div class="courseClassification ">
                    <div class="label flex">选择您需要的服务 <div class="required">
                            *
                        </div>
                    </div>
                    <div class="courseClassification_list flex flex-wrap">
                        <div class="course_name display  pointer" :class="courseIndexA == index ? 'courseActive' : ''"
                            v-for="(item, index) in courseClassification" :key="index"
                            @click="courseClassificationBtn(item, index)">
                            {{ item.menuName }}
                            <img v-if="courseIndexA == index" class="icon_yes" src="../assets/img/newHome/icon_yes.png"
                                alt="">
                        </div>

                    </div>
                </div>
                <div class="formData flex">
                    <div class="list userName">
                        <div class="label flex">姓名 <div class="required">
                                *
                            </div>
                        </div>
                        <input v-model="params.userName" type="text" placeholder="请输入姓名">
                        <div class="prompt_div display" v-if="isName == true">
                            请输入姓名
                        </div>
                    </div>
                    <div class="list">
                        <div class="label flex">电话 <div class="required">
                                *
                            </div>
                        </div>
                        <input v-model="params.phoneNumber" maxlength="11" type="text" placeholder="请输入手机号">
                        <div class="prompt_div display" v-if="isPhone == true">
                            请输入手机号
                        </div>
                    </div>
                </div>
                <div class="btn display pointer" @click="makeAppointmen">立即预约</div>
                <img @click="closeBtn" class="close_img pointer" src="../assets/img/newHome/close_img.png" alt="">
            </div>
        </div>
    </div>
</template> 

<script>
import Footer from '../components/footer.vue'
import PlLazy from '../components/plLazy.vue'
export default {
    components: {
        Footer,
        PlLazy,
    },
    data() {
        return {
            isShow: false,
            goTopShow: false,
            isName: false,
            isPhone: false,
            isFiexd: false,
            navBarIndex: 1,
            classifyIndex: null,
            courseIndex: null,
            courseIndexA: null,
            childrenList: [],
            courseClassification: [],
            popover: "",
            navBarList: [
                {
                    id: 1,
                    name: '个人服务',
                    url: '',
                },

                // {
                // 	id: 3,
                // 	name: '金牌讲师',
                // 	url: '/homes/lecturer',
                // },
                {
                    id: 10,
                    name: '企业服务',
                    url: '',
                },
                {
                    id: 11,
                    name: '证书查询',
                    url: '/homes/certificateQueryPage',
                },
                {
                    id: 4,
                    name: '网校平台',
                    url: 'http://youwen.golkia-school.com/',
                },

                //   {
                //       id:6,
                //       name:"学员风采",
                //       url:"/homes/student"
                //   },

                //   {
                //       id: 7,
                //       name: "公开课",
                //       url: "/homes/publicClass"
                //   },
                //   {
                //       id: 4,
                //       name: "招生就业",
                //       url: "/homes/recruitStudent",
                //       type: 1,
                //   },

                //   {
                //       id: 5,
                //       name: "对外合作",
                //       url: "/homes/recruitStudent",
                //       type: 2,
                //   },
                {
                    id: 8,
                    name: '新闻资讯',
                    url: '/homes/newsCenter',
                },
                //   {
                //       id: 9,
                //       name: "立即报名",
                //       url:"/homes/signUp"
                //   },
                // {
                // 	id: 10,
                // 	name: '联系我们',
                // 	url: '/homes/contactUs',
                // },
                {
                    id: 2,
                    name: '关于我们',
                    url: '/homes/aboutUs',
                },
            ],
            courseList: [
                {
                    id: 1,
                    name: '装饰工程',
                },
                {
                    id: 2,
                    name: '建筑工程',
                },
                {
                    id: 3,
                    name: '特种作业',
                },
                {
                    id: 4,
                    name: '建筑工程',
                },
            ],
            sidebarList: [
                {
                    id: 5,
                    name: '个人服务',
                    imgUrl: require('../assets/img/sidebar/1.png'),
                    type: 6,
                    url: '/homes/specialOperations',
                },
                {
                    id: 10,
                    name: '企业服务',
                    imgUrl: require('../assets/img/sidebar/2.png'),
                    type: 1,
                    url: '/homes/enterpriseService/highTechEnterprise',
                },
                {
                    id: 20,
                    name: '领取',
                    names: "免费教材",
                    imgUrl: require('../assets/img/sidebar/5.png'),

                },
                {
                    id: 21,
                    name: '预约',
                    names: "免费试课",
                    imgUrl: require('../assets/img/sidebar/6.png'),

                },
                {
                    id: 22,
                    name: '申请',
                    names: "学费分期",
                    imgUrl: require('../assets/img/sidebar/7.png'),

                },
            ],
            GejGroup: [],
            classificationData: [],
            params: {
                userName: '',
                phoneNumber: '',
                courseId: 1,
                courseType: null,
                courseName: '',
                email: '',
                companyName: '',
                questionContent: '',
                typeQualification: '',
                certificationProject: '',
                locationEnterprise: '',
                dateEstablishment: '',
            },
        }
    },
    // 监听路由变化
    watch: {
        $route: 'urlName',
    },
    created() {
        if (this.$store.state.workData.length != 0) {
            this.childrenList = this.$store.state.workData[0].children
        }
        if (this.$route.query.ids) {
            this.navBarIndex = this.$route.query.ids
        } else {
            this.navBarIndex = 1
        }


    },
    mounted() {
        this.getWork()
        this.getGejGroup()
        window.addEventListener('scroll', this.handleScroll)
        this.info()
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },

    methods: {
        //获取个人、企业服务分类
        info() {
            this.api.menuTreeselect().then((res) => {
                console.log(res, '获取个人、企业服务分类')
                this.$store.commit('getPersonData', res.data.person)
                this.$store.commit('getCompanyData', res.data.company)

                this.courseClassification = res.data.person
            })
        },
        //获取果尔佳站群
        getGejGroup() {
            this.api.findGejStationGroup().then((res) => {
                this.GejGroup = res.data || []
            })
        },
        //获取工种
        getWork() {
            this.api.findOneTypeWork().then((res) => {
                if (res.code == 0) {
                    this.$store.commit('getWorkData', res.data)
                }
            })
        },
        handleScroll() {
            this.scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop
            if (this.scrollTop > 110) {
                this.isFiexd = true
            } else {
                this.isFiexd = false
            }
            if (this.scrollTop > 500) {
                //设置页面滑动多少显示按钮
                this.goTopShow = true
            } else {
                this.goTopShow = false
            }
        },
        // 监听url参数变化
        urlName() {
            this.navBarIndex = this.$route.query.ids
        },
        //点击导航
        changeBtn(item, index) {
            this.navBarIndex = item.id
            if (index != 0) {
                if (item.id == 4) {
                    window.open(item.url, '_blank')
                } else {
                    this.$router.push({
                        path: item.url,
                        query: {
                            ids: item.id,
                            type: item.type,
                        },
                    })
                }
            }
        },
        toHome() {
            this.$router.push('/homes/index')
            this.navBarIndex = null
        },
        mouseEnter(index) {
            if (index == 0) {
                this.classificationData = this.$store.state.personData
            } else if (index == 1) {
                this.classificationData = this.$store.state.companyData
            }
            console.log(this.classificationData)
            this.classifyIndex = index
        },
        //移入
        enter(item, index) {
            this.courseIndex = index
            this.childrenList = item
            this.isShow = false
        },
        leave() { },
        toCourse(item, index) {

            if (item.name == '个人服务') {
                this.courseClassification = this.$store.state.personData
                this.popover = item.name
            } else if (item.name == '企业服务') {
                this.courseClassification = this.$store.state.companyData
                this.popover = item.name
            } else {
                this.service()
            }
            // let ids = null
            // if (item.name == '个人服务') {
            //     ids = 1
            // } else {
            //     ids = 10
            // }
            // this.$router.push({
            //     path: item.url,
            //     query: {
            //         ids: ids,
            //     },
            // })
            // this.navBarIndex = 1
            // this.toTop()
        },
        //联系客服
        service() {
            // document.getElementById('layui-kflayer19911116').style.display = 'block'
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
        //回到顶部
        toTop() {
            let timer = null,
                _that = this
            cancelAnimationFrame(timer)
            timer = requestAnimationFrame(function fn() {
                if (_that.scrollTop > 0) {
                    _that.scrollTop -= 100 //回到顶部的速度
                    document.body.scrollTop = document.documentElement.scrollTop =
                        _that.scrollTop
                    timer = requestAnimationFrame(fn)
                } else {
                    cancelAnimationFrame(timer)
                    _that.goTopShow = false
                }
            })
        },
        //个人/企业服务按钮
        pullDown(name) {
            if (name == '技能培训' || name == '安全培训') {
                this.isShow = !this.isShow
            } else {
                this.classifyIndex = null
                let url = ''
                let ids = 1
                if (name == '特种作业') {
                    url = '/homes/specialOperations'
                    ids = 1
                } else if (name == '执业资格') {
                    url = '/homes/registered'
                    ids = 1
                } else if (name == '学历提升') {
                    url = '/homes/educationBg'
                    ids = 1
                } else if (name == '职称申报') {
                    url = '/homes/professional'
                    ids = 1
                } else if (name == '其他培训') {
                    url = '/homes/otherTraining'
                    ids = 1
                } else if (name == '深户办理') {
                    url = '/homes/applyHouseholdAccount'
                    ids = 1
                } else if (name == '职业技能等级认定') {
                    url = '/homes/vocationalSkillLevel'
                    ids = 1
                } else if (name == '劳务信息') {
                    url = '/homes/serviceInfo'
                    ids = 1
                } else if (name == '公务员考试') {
                    url = '/homes/civilServant'
                    ids = 1
                } else if (name == '工业自动化人才培训') {
                    url = '/homes/talentTraining'
                    ids = 1
                } else if (name == '高新企业认定申报') {
                    url = '/homes/enterpriseService/highTechEnterprise'
                    ids = 10
                } else if (name == '建筑工程资质申报') {
                    url = '/homes/enterpriseService/constructionProject'
                    ids = 10
                } else if (name == '职业技能等级评价机构申报') {
                    url = '/homes/enterpriseService/vocationalSkill'
                    ids = 10
                } else if (name == '产业工人培训') {
                    url = '/homes/enterpriseService/workerTraining'
                    ids = 10
                } else if (name == '企业服务体系认证') {
                    url = '/homes/enterpriseService/systemCertification'
                    ids = 10
                } else if (name == '企业补贴申报') {
                    url = '/homes/enterpriseService/enterpriseSubsidy'
                    ids = 10
                } else if (name == '场地租赁') {
                    url = '/homes/enterpriseService/siteLease'
                    ids = 10
                } else if (name == '劳务派遣') {
                    url = '/homes/enterpriseService/laborDispatch'
                    ids = 10
                }

                this.$router.push({
                    path: url,
                    query: {
                        ids: ids,
                    },
                })
                this.navBarIndex = 1

                this.toTop()
            }
        },
        sublevel(name) {
            let url = ''
            let ids = ''
            this.classifyIndex = null
            if (name == '施工现场专业人员') {
                url = '/homes/constructionSite'
                ids = 1
            } else if (name == '建筑工程施工类') {
                url = '/homes/constructionEngineering'
                ids = 1
            } else if (name == '装饰装修类') {
                url = '/homes/adornmentDecorated'
                ids = 1
            } else if (name == '企业安全培训') {
                url = '/homes/safetyTraining'
                ids = 10
            } else if (name == '安全员ABC证') {
                url = '/homes/enterpriseService/safetyOfficer'
                ids = 10
            }
            this.$router.push({
                path: url,
                query: {
                    ids: ids,
                },
            })
            this.navBarIndex = 1

            this.toTop()
        },
        mouseEnterList(item) {
            console.log(item)
            if (item == '技能培训' || item == '安全培训') {
                this.isShow = true
            } else {
                this.isShow = false
            }
        },
        courseClassificationBtn(item, index) {
            console.log(item, index);
            this.params.courseType = 0
            this.params.courseName = item.menuName
            this.courseIndexA = index
        },
        closeBtn() {
            this.popover = ''
            this.courseIndexA = null
            this.formData = {
                userName: '',
                userPhone: '',
            }
        },
        //立即预约
        makeAppointmen() {
            if (this.params.userName == '') {
                this.isName = true
                return this.$messge.error('请输入姓名')
            } else {
                this.isName = false
            }
            if (!/^[0-9]{11}$/.test(this.params.phoneNumber)) {
                this.isPhone = true
                return this.$messge.error('请输入手机号')
            } else {
                this.isPhone = false
            }
            if (this.params.courseName == '') {
                this.isName = true
                return this.$messge.error('请选择您需要的服务')
            }
            this.api.addCourseApply(this.params).then((res) => {
                if (res.code == 0) {
                    this.$message.success('领取成功！')
                    this.params = {
                        userName: '',
                        phoneNumber: '',
                        courseId: 1,
                        courseType: null,
                        courseName: '公务员考试',
                        email: '',
                        companyName: '',
                        questionContent: '',
                        typeQualification: '',
                        certificationProject: '',
                        locationEnterprise: '',
                        dateEstablishment: '',
                    }
                    this.isName = false
                    this.isPhone = false
                } else {
                    this.$message.success(res.msg)
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.home {
    .content_box {
        min-height: 1000px;
    }

    .head {
        height: 110px;

        .head_logo {
            width: 337px;

            img {
                width: 337px;
                height: 74px;
            }
        }

        .fr {
            .icon_phone {
                margin-right: 12px;

                img {
                    width: 44px;
                    height: 44px;
                }
            }

            .service_hotline {
                font-size: 16px;
                color: #666666;
                line-height: 16px;
                margin-bottom: 9px;
            }

            .phone {
                font-size: 30px;
                font-family: Arial;
                font-weight: bold;
                color: #ff881e;
                line-height: 16px;
            }
        }

        .links:hover {
            .child_div {
                opacity: 1;
                visibility: visible;
                transition: opacity 1s;
                animation: none;
            }
        }

        .links {
            width: 240px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #dddddd;
            position: relative;
            margin-left: 20px;

            .links_div {
                width: 100%;
                height: 100%;
                padding: 0 18px;
                box-sizing: border-box;

                .name {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;

                    img {
                        margin-top: 5px;
                    }
                }
            }

            .child_div {
                width: 240px;
                position: absolute;
                top: 38px;
                left: -1px;
                background: #ffffff;
                border: 1px solid #ddd;
                border-bottom: 0;
                z-index: 9999;
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.3s 0.3s, visibility 0.3s;
                animation: control-pointer 0.3s;

                .list_a {
                    width: 238px;
                    height: 42px;
                    padding-left: 18px;
                    box-sizing: border-box;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    border-bottom: 1px solid #dddddd;
                }

                .list_a:hover {
                    background: #164d89;
                    color: #fff;
                }
            }
        }
    }

    .navBar {
        width: 100%;
        height: 54px;
        background: #003c7e;
        position: relative;

        .classify_box {
            width: 100%;
            height: 540px;
            background: white;
            position: absolute;
            top: 54px;
            left: 0;
            z-index: 6666;

            .classifyCont {
                .fl {
                    width: 850px;
                    padding-top: 52px;

                    ul {
                        .ul_list:hover {
                            .name {
                                color: #ff881e !important;
                            }
                        }

                        .ul_list {
                            width: 136px;
                            margin-bottom: 43px;

                            img {
                                width: 60px;
                                height: 60px;
                            }

                            .name {
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #000000;
                                margin-top: 10px;
                                position: relative;
                                text-align: center;

                                .more_ul {
                                    position: absolute;
                                    top: 30px;
                                    left: 0;
                                    background: white;

                                    .more_li:hover {
                                        color: #ff881e !important;
                                    }

                                    .more_li {
                                        width: 130px;
                                        height: 30px;
                                        font-size: 16px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #666666;
                                    }
                                }

                                .triangle {
                                    width: 0;
                                    height: 0;
                                    border-top: 5px solid #666666;
                                    border-right: 5px solid transparent;
                                    border-left: 5px solid transparent;
                                    margin-top: 4px;
                                    margin-left: 6px;
                                }
                            }
                        }
                    }
                }

                .fr {
                    padding-top: 40px;
                    margin-left: 40px;

                    img {
                        width: 480px;
                        height: 280px;
                    }
                }
            }
        }

        .list:hover {
            background: #ff881e;
        }

        .list {
            width: 25%;
            height: 54px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            position: relative;

            .icon_class {
                width: 23px;
                height: 15px;
                margin-right: 11px;
            }

            .line {
                width: 1px;
                height: 16px;
                background: #164d89;
                position: absolute;
                top: 50%;
                right: -1px;
                margin-top: -6px;
            }

            .hot_course {
                width: 100%;
                height: 540px;
                opacity: 0.7;
                position: absolute;
                top: 54px;
                bottom: 0;
                left: 0px;
                z-index: 99999;
                padding: 0px;
                box-sizing: border-box;
                background: #111;

                .cont {
                    .course_avtive {
                        background: #000 !important;
                        opacity: 1 !important;
                        z-index: 11;
                    }

                    .course_list {
                        width: 300px;
                        height: 62px;
                        padding-left: 25px;
                        padding-right: 13px;
                        box-sizing: border-box;
                        border-bottom: 1px solid#595959;

                        .name {
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #ffffff;
                        }



                        img {
                            width: 10px;
                            height: 18px;
                        }
                    }
                }

                .childBox {
                    width: 300px;
                    opacity: 1;
                    position: absolute;
                    left: 300px;
                    bottom: 251px;
                    z-index: 10;
                    padding: 0px;
                    box-sizing: border-box;
                    background: #000;
                    padding-top: 16px;

                    .child_list {
                        height: 50px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        padding-left: 30px;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .navActive {
            background: #ff881e;
        }
    }

    .navBar .list:last-child .line {
        display: none;
    }

    @media screen and (max-width: 1380px) {
        .sidebar {
            position: fixed;
            top: 168px;
            right: 0px;
            z-index: 1000000;

            .wx_div {
                width: 86px;
                height: 96px;
                background: #ffffff;
                box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.25);
                padding: 6px 5px;
                box-sizing: border-box;
                margin-bottom: 10px;

                img {
                    width: 78px;
                    height: 78px;
                }

                .wechatPublicAccount {
                    font-size: 10px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 10px;
                }
            }

            .list:last-child {
                height: 68px;
                border-bottom: none !important;
            }

            .list {
                width: 68px;
                padding: 10px 0;
                background: #003c7e;
                border-bottom: 1px solid #fff;

                img {
                    width: 28px;
                    height: 28px;
                }

                .top_div {
                    margin-top: 0 !important;
                }

                .name {
                    font-size: 13px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #fafafa;
                    line-height: 14px;
                    margin-top: 8px;
                }

                .names {
                    font-size: 10px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #fafafa;
                    line-height: 14px;
                }

            }
        }
    }

    @media screen and (min-width: 1380px) {
        .sidebar {
            position: fixed;
            top: 168px;
            right: 43px;
            z-index: 1000000;

            .wx_div {
                width: 100px;
                height: 112px;
                background: #ffffff;
                box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.25);
                padding: 6px 5px;
                box-sizing: border-box;
                margin-bottom: 12px;

                img {
                    width: 90px;
                    height: 90px;
                }

                .wechatPublicAccount {
                    font-size: 11px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 11px;
                }
            }

            .list:last-child {
                height: 72px;
                border-bottom: none !important;
            }

            .list {
                width: 74px;
                padding: 10px 0;
                background: #003c7e;
                border-bottom: 1px solid #fff;

                img {
                    width: 32px;
                    height: 32px;
                }

                .top_div {
                    margin-top: 0 !important;
                }

                .name {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #fafafa;
                    line-height: 16px;
                    margin-top: 7px;
                }

                .names {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #fafafa;
                    line-height: 14px;
                }
            }
        }
    }
}

.fixedHead {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 888;
}

.popover_Box {
    width: 100%;
    height: 100%;

    .maskLayer {
        width: 100%;
        height: 100%;
        background: #333;
        opacity: 0.6;
        position: fixed;
        top: 0px;
        right: 0px;
        z-index: 999999999999;
    }

    .popove_main {
        width: 800px;
        height: 580px;
        background: #FFFFFF;
        border: 1px solid #DEDEDE;
        border-radius: 8px;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 9999999999909;
        margin-top: -300px;
        margin-left: -380px;
        padding-left: 33px;
        box-sizing: border-box;


        .close_img {
            width: 48px;
            height: 48px;
            position: absolute;
            top: 20px;
            right: 26px;
        }

        .title {
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            text-align: center;
            line-height: 28px;
            margin-top: 28px;
        }

        .courseClassification {
            margin-top: 30px;

            .label {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #888888;
                line-height: 18px;
                margin-bottom: 18px;
            }

            .required {
                color: #FF0000;
            }

            .courseClassification_list {
                .course_name {
                    width: 178px;
                    height: 68px;
                    background: #F5F5F5;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    margin-right: 8px;
                    text-align: center;
                    padding: 0 17px;
                    box-sizing: border-box;
                    margin-bottom: 14px;
                    position: relative;

                    .icon_yes {
                        width: 18px;
                        height: 13px;
                        position: absolute;
                        top: 9px;
                        right: 7px;
                    }
                }

                .courseActive {
                    background: #E7ECF2 !important;
                    color: #003C7E !important;
                }
            }
        }

        .formData {
            .userName {
                margin-right: 32px;
            }

            .list {
                position: relative;

                .label {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #888888;
                    line-height: 18px;
                    margin-bottom: 12px;
                }

                .required {
                    color: #FF0000;
                }

                input {
                    width: 345px;
                    height: 60px;
                    background: #F5F5F5;
                    font-size: 18px;
                    padding-left: 14px;
                    box-sizing: border-box;
                }

                .prompt_div {
                    width: 144px;
                    height: 66px;
                    background: white;
                    background: url('../assets/img/newHome/prompt.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FF0000;
                    padding-top: 10px;
                    position: absolute;
                    left: 10px;
                    bottom: -66px;
                }
            }
        }

        .btn {
            width: 400px;
            height: 60px;
            background: #FF891F;
            border-radius: 5px;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            margin: 0 auto;
            margin-top: 16px;
        }
    }
}
</style>