<template>
    <div class="footer">
        <div class="content">
            <div class="content flex engineering_box">
                <div class="hot_course flex">
                    <div class="list">
                        <div class="course_title">个人服务</div>
                        <div class="course_list">
                            <div class="course_name pointer" v-for="(item, index) in $store.state.personData"
                                :key="index" @click="toCourse(item.menuName, index)">
                                {{ item.menuName }}
                            </div>
                        </div>
                    </div>
                    <div class="list">
                        <div class="course_title">企业服务</div>
                        <div class="course_list">
                            <div class="course_name pointer" v-for="(item, index) in $store.state.companyData"
                                :key="index" @click="toCourse(item.menuName, index)">
                                {{ item.menuName }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="focus_GEJ">
                    <div class="focus_GEJ_fr ">
                        <div class="GEJ_info">
                            <img class="logo" :src="relationData.iconUrl" alt="" />
                            <div class="flex focus_GEJ_div">
                                <img class="phone_img" src="@/assets/img/home/phone2.png" alt="" />
                                <div class="phone_schoolAddress">
                                    <div class="phone">{{ relationData.fax }}</div>
                                    <div class="schoolAddress flex">
                                        <div class="label">学校地址：</div>
                                        <div class="address">
                                            {{ relationData.schoolAddress }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="public display column">
                                <img class="public_code" :src="relationData.qrCode" alt="" />
                                <div>关注公众号</div>
                            </div>
                            <div class="public display column">
                                <img class="public_code" :src="relationData.appletQrCodeUrl" alt="" />
                                <div>学校小程序</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="footer_navBar display">
                <div class="list pointer" @click="jump(item, index)" v-for="(item, index) in navList" :key="index">
                    {{ item.name }}
                </div>
            </div>
            <div class="blogroll display">
                <div class="link_div">友情链接 :</div>
                <div class="list pointer" @click="blogrollBtn(item.url)" v-for="(item, index) in blogroll" :key="index">
                    {{ item.name }}
                </div>
            </div>
            <div class="record display pointer" @click="toLink">
                Copyright © 2006 - 2028 Golkia. All Rights Reserved. 果尔佳公司 版权所有
                粤ICP备2022070763号-1
            </div>
        </div>
        <div class="sign_up_box " v-if="isShow && applyShow">
            <div class="content sign_up">
                <div class="display">
                    <div class="title flex-center">
                        <div class="free_classes">免费试学 名额有限</div>
                        <div class="">学一技之长，改一生命运</div>
                    </div>
                </div>
                <div class="formData display">
                    <div class="list display list_name">
                        <input v-model="params.userName" type="text" placeholder="请输入姓名">
                    </div>
                    <div class="list display list_phone">
                        <input v-model="params.phoneNumber" type="text" maxlength="11" placeholder="请输入手机号">
                    </div>
                    <!-- <div class="list display list_select">
                        <el-select v-model="params.courseId" placeholder="选择试听课程" clearable @change="selectBtn">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div> -->
                    <div class="sign_up_btn display pointer" @click="submitBtn">
                        立即报名
                    </div>
                </div>
                <img class="off pointer" @click="isShow = false" src="@/assets/img/home/off.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            relationData: {},
            isShow: true,
            applyShow: true,
            navList: [
                {
                    id: 1,
                    name: '个人服务',
                    url: '/homes/specialOperations',
                },

                // {
                // 	id: 3,
                // 	name: '金牌讲师',
                // 	url: '/homes/lecturer',
                // },
                {
                    id: 10,
                    name: '企业服务',
                    url: '/homes/enterpriseService/highTechEnterprise',
                },
                {
                    id: 11,
                    name: '证书查询',
                    url: '/homes/certificateQueryPage',
                },
                {
                    id: 4,
                    name: '网校平台',
                    url: 'http://youwen.golkia-school.com/',
                },

                //   {
                //       id:6,
                //       name:"学员风采",
                //       url:"/homes/student"
                //   },

                //   {
                //       id: 7,
                //       name: "公开课",
                //       url: "/homes/publicClass"
                //   },
                //   {
                //       id: 4,
                //       name: "招生就业",
                //       url: "/homes/recruitStudent",
                //       type: 1,
                //   },

                //   {
                //       id: 5,
                //       name: "对外合作",
                //       url: "/homes/recruitStudent",
                //       type: 2,
                //   },
                {
                    id: 8,
                    name: '新闻资讯',
                    url: '/homes/newsCenter',
                },
                //   {
                //       id: 9,
                //       name: "立即报名",
                //       url:"/homes/signUp"
                //   },
                // {
                // 	id: 10,
                // 	name: '联系我们',
                // 	url: '/homes/contactUs',
                // },
                {
                    id: 2,
                    name: '关于我们',
                    url: '/homes/aboutUs',
                },
            ],
            blogroll: [
                {
                    id: 1,
                    name: '果尔佳集团',
                    url: 'http://www.golkia.com/',
                },
                {
                    id: 2,
                    name: '筑手建筑防护超市',
                    url: 'https://www.golkia-mall.com/',
                },
                {
                    id: 3,
                    name: '果尔佳建筑产业',
                    url: 'http://www.golkia.net/',
                },
                {
                    id: 3,
                    name: '果尔佳服务',
                    url: 'https://www.golkia-mall.com/#/serviceSystem/home',
                },
            ],
            formData: {
                name: '',
                phone: '',
            },
            options: [],
            value: '',
            params: {
                userName: '',
                phoneNumber: '',
                courseId: 1,
                courseType: 0,
            },
            scrollTop: null,
        }
    },
    created() {
        this.getInfo()
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
        // let url = window.location.href
        // if (url.indexOf("/homes/index")>= 0){
        //     this.applyShow = true
        // }else{
        //     this.applyShow = false
        // }
        this.getCourse()
    },
    methods: {
        getInfo() {
            this.api.findPlatformInformation().then((res) => {
                if (res.data) {
                    this.relationData = res.data || []
                    this.$store.commit('getInfo', res.data)
                }
            })
        },
        handleScroll() {
            this.scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop
            if (this.scrollTop > 110) {
                this.isFiexd = true
            } else {
                this.isFiexd = false
            }
            if (this.scrollTop > 500) {
                //设置页面滑动多少显示按钮
                this.goTopShow = true
            } else {
                this.goTopShow = false
            }
        },
        getCourse() {
            this.api.findAllCourse().then((res) => {
                if (res.code == 0) {
                    let arr = []
                    let obj = {}
                    if (res.data.length != 0) {
                        res.data.forEach((item) => {
                            obj = {
                                value: item.id,
                                label: item.courseName,
                                courseType: item.courseType,
                            }
                            arr.push(obj)
                        })
                        this.options = arr
                    }
                }
            })
        },
        selectBtn(val) {
            this.options.forEach((item) => {
                if (item.value == val) {
                    this.params.courseType = item.courseType
                }
            })
        },
        // 报名
        submitBtn() {
            if (this.params.userName == '') {
                return this.$message.error('请输入您的姓名')
            }

            if (!/^[0-9]{11}$/.test(this.params.phoneNumber)) {
                return this.$message.error('请正确输入手机号')
            }

            this.api.addCourseApply(this.params).then((res) => {
                if (res.code == 0) {
                    this.$message.success('成功！')
                    this.params = {
                        userName: '',
                        phoneNumber: '',
                        courseId: 1,
                        courseType: 0,
                    }
                }
            })
        },
        jump(item, index) {
            if (item.id == 4) {
                window.open(item.url, '_blank')
            } else {
                let routeData = this.$router.resolve({
                    path: item.url,
                    query: {
                        ids: item.id,
                        type: item.type,
                    },
                })
                window.open(routeData.href, '_blank')
            }
        },

        toLink() {
            window.open('https://beian.miit.gov.cn/', '_blank')
        },
        blogrollBtn(url) {
            window.open(url, '_blank')
        },

        toCourse(name, index) {
            this.toTop()
            let url = ''
            let ids = 1
            if (name == '特种作业') {
                url = '/homes/specialOperations'
                ids = 1
            } else if (name == '执业资格') {
                url = '/homes/registered'
                ids = 1
            } else if (name == '技能培训') {
                url = '/homes/constructionSite'
                ids = 1
            } else if (name == '学历提升') {
                url = '/homes/educationBg'
                ids = 1
            } else if (name == '职称申报') {
                url = '/homes/professional'
                ids = 1
            } else if (name == '其他培训') {
                url = '/homes/otherTraining'
                ids = 1
            } else if (name == '深户办理') {
                url = '/homes/applyHouseholdAccount'
                ids = 1
            } else if (name == '职业技能等级认定') {
                url = '/homes/vocationalSkillLevel'
                ids = 1
            } else if (name == '劳务信息') {
                url = '/homes/serviceInfo'
                ids = 1
            } else if (name == '公务员考试') {
                url = '/homes/civilServant'
                ids = 1
            } else if (name == '工业自动化人才培训') {
                url = '/homes/talentTraining'
                ids = 1
            } else if (name == '高新企业认定申报') {
                url = '/homes/enterpriseService/highTechEnterprise'
                ids = 10
            } else if (name == '建筑工程资质申报') {
                url = '/homes/enterpriseService/constructionProject'
                ids = 10
            } else if (name == '职业技能等级评价机构申报') {
                url = '/homes/enterpriseService/vocationalSkill'
                ids = 10
            } else if (name == '安全培训') {
                url = '/homes/safetyTraining'
                ids = 10
            } else if (name == '产业工人培训') {
                url = '/homes/enterpriseService/workerTraining'
                ids = 10
            } else if (name == '企业服务体系认证') {
                url = '/homes/enterpriseService/systemCertification'
                ids = 10
            } else if (name == '企业补贴申报') {
                url = '/homes/enterpriseService/enterpriseSubsidy'
                ids = 10
            } else if (name == '场地租赁') {
                url = '/homes/enterpriseService/siteLease'
                ids = 10
            } else if (name == '劳务派遣') {
                url = '/homes/enterpriseService/laborDispatch'
                ids = 10
            }

            this.$router.push({
                path: url,
                query: {
                    ids: ids,
                },
            })
        },
        //回到顶部
        toTop() {
            let timer = null,
                _that = this
            cancelAnimationFrame(timer)
            timer = requestAnimationFrame(function fn() {
                if (_that.scrollTop > 0) {
                    _that.scrollTop -= 100 //回到顶部的速度
                    document.body.scrollTop = document.documentElement.scrollTop =
                        _that.scrollTop
                    timer = requestAnimationFrame(fn)
                } else {
                    cancelAnimationFrame(timer)
                    _that.goTopShow = false
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.footer {
    // height: 554px;
    background: url('../assets/img/home/footbg.png');
    background-size: 100% 100%;
    padding-top: 46px;
    padding-bottom: 81px;

    .engineering_box {
        padding-bottom: 40px;
        border-bottom: 1px solid #959595;

        .hot_course {
            width: 630px;

            .list {
                border-left: 1px solid #414141;
                border-right: 1px solid #414141;
                padding: 0 60px;
            }

            .course_title {
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #fafafa;
                line-height: 24px;
                margin-bottom: 28px;
            }

            .course_list {
                .course_name {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #fafafa;
                    line-height: 30px;
                }
            }
        }
    }

    .focus_GEJ {
        margin-top: -35px;

        .focus_GEJ_fl {
            .logo {
                width: 322px;
                height: 64px;
            }
        }

        .focus_GEJ_fr {
            margin-top: 35px;
            margin-left: 53px;

            .GEJ_info {
                .logo {
                    width: 322px;
                    height: 64px;
                }

                .focus_GEJ_div {
                    .phone_img {
                        margin-top: 16px;
                        width: 34px;
                        height: 34px;
                        margin-right: 22px;
                    }

                    .phone_schoolAddress {
                        margin-top: 18px;

                        .phone {
                            font-size: 30px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #e8e8e8;
                            line-height: 30px;
                        }

                        .schoolAddress {
                            margin-top: 17px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #e8e8e8;

                            .label {}

                            .address {
                                width: 270px;
                            }
                        }
                    }
                }
            }

            .public:nth-child(2) {
                margin-left: 66px;
            }

            .public {
                margin-top: 25px;
                margin-left: 54px;

                .public_code {
                    width: 120px;
                    height: 120px;
                }

                div {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #e8e8e8;
                    margin-top: 10px;
                }
            }
        }
    }

    .footer_navBar {
        height: 62px;
        border-bottom: 1px solid #959595;

        .list {
            padding: 0 22px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #dcdcdc;
            border-right: 1px solid #b0b0b0;
            line-height: 16px;
        }

        .list:last-child {
            border-right: 0 !important;
        }
    }

    .blogroll {
        margin-top: 27px;

        div {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #c3c3c3;
            line-height: 12px;
        }

        .list {
            border-right: 1px solid #b0b0b0;
            padding: 0 10px;
        }

        .list:last-child {
            border-right: none;
        }
    }

    .record {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #c3c3c3;
        line-height: 12px;
        margin-top: 16px;
    }

    .sign_up_box {
        width: 100%;
        height: 150px;
        background: url('../assets/img/home/bm.png') no-repeat;
        background-size: 100% 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 999;

        .sign_up {
            position: relative;

            .off {
                width: 38px;
                height: 38px;
                position: absolute;
                top: 0px;
                right: 32px;
            }
        }

        .title {
            margin-top: 30px;

            div {
                font-size: 30px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
                line-height: 28px;
            }

            .free_classes {
                color: #FF881E;
                margin-right: 15px;
            }
        }
    }

    .formData {
        margin-top: 15px;

        .list {
            width: 268px;
            height: 40px;
            background: #FFFFFF;
            margin-left: 12px;
            padding-left: 14px;

            input {
                width: 100%;
                font-size: 14px;
            }
        }

        // .list_name {
        //     width: 120px;
        //     padding-left: 14px;
        // }

        // .list_phone {
        //     width: 180px;
        //     padding-left: 14px;
        // }

        .list_select {
            width: 260px;

            ::v-deep.el-input__inner {
                width: 260px;
                border: none !important;
            }
        }

        .sign_up_btn {
            width: 188px;
            height: 40px;
            background: #FF881E;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin-left: 12px;
        }
    }
}
</style>